.privacy-policy {
    font-family: 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    line-height: 1.6;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .policy-header {
    text-align: center;
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
  }
  
  .policy-header h1 {
    color: #6e48aa;
    margin-bottom: 0.5rem;
    font-size: 2rem;
  }
  
  .last-updated {
    color: #777;
    font-style: italic;
    font-size: 0.9rem;
  }
  
  .policy-section {
    margin-bottom: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #eee;
  }
  
  .policy-section h2 {
    color: #6e48aa;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  
  .subsection {
    margin: 1.5rem 0;
  }
  
  .policy-section h3 {
    color: #444;
    margin: 1.5rem 0 1rem;
    font-size: 1.2rem;
  }
  
  .policy-section ul {
    padding-left: 1.5rem;
    margin: 1rem 0;
  }
  
  .policy-section li {
    margin-bottom: 0.5rem;
  }
  
  .contact-section {
    background-color: #f0f0f0;
    padding: 1.5rem;
    border-radius: 8px;
    margin-top: 2rem;
  }
  
  .contact-section h2 {
    color: #6e48aa;
    margin-bottom: 1rem;
  }
  
  .contact-link {
    color: #6e48aa;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    margin-top: 0.5rem;
  }
  
  .contact-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 1.5rem;
    }
    
    .policy-header h1 {
      font-size: 1.7rem;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy {
      padding: 1rem;
    }
    
    .policy-section h2 {
      font-size: 1.3rem;
    }
  }